import { Select, PageHeader } from "antd";
import { observer } from "mobx-react";
import ListComponent from "./component/ListComponent";
import BreadcrumbComponent from "../../../component/BreadcrumbComponent";
import RecordPerPage from "../../../component/RecordPerPage";
import { BreadcrumbConfig } from "../../../config/BreadcrumbConfig";
import useStore from "../../../store";
import DRFComponent from "./component/ApplyDRFComponent";
import { useState } from "react";
import HistoryListComponent from "./component/HistoryListComponent";
import RevertRequestListComponent from "./component/RevertRequestListComponent";
import { DRFListType } from "../../../utils/GlobalFunction";

// import { vsmNotify } from "../../../config/messages";

const ManageDRF = observer((props) => {
	const [drfModal, setDrfModal] = useState(false)
	const [viewTypeId, setViewTypeId] = useState(10);
	const {
		ManageDRFStore: {
			setPageSize,
			per_page,
			setEditValues,
			setTypeMode
		},
	} = useStore();

	// Open & Close  form for DRF
	const openDRFModal = (data, openHistory) => {
		setTypeMode(openHistory)
		setEditValues(data);
		setDrfModal(true);
	};
	const closeDRFModal = () => setDrfModal(false);

	return (
		<PageHeader
			title={BreadcrumbConfig.ManageDRF.title}
			className="tableAreaSec"
			extra={
				<BreadcrumbComponent items={BreadcrumbConfig.ManageDRF.path} />
			}
		>
			<div className="listCountNew">
				<Select onChange={(e) => { setViewTypeId(e) }} defaultValue={"Pending"} style={{ width: "150px" }}>
					{DRFListType.map((drf) => {
						return <Select.Option value={drf.id}>{drf.name}</Select.Option>
					})}
				</Select>
				<RecordPerPage
					key="2"
					style={{ width: "150px" }}
					defaultValue={per_page + " per page"}
					onChange={setPageSize}
				/>
			</div>
			{/* DRF */}
			<DRFComponent
				visible={drfModal}
				close={closeDRFModal}
			/>
			{viewTypeId === 10 ?
				<ListComponent openDRFModal={openDRFModal} />
				: viewTypeId === 20 ?
					<RevertRequestListComponent openDRFModal={openDRFModal} openForm="openRevertRequest" />
					:
					<HistoryListComponent openDRFModal={openDRFModal} openForm="openHistory" />
			}
		</PageHeader>
	);
});

export default ManageDRF;
